<template>
  <div>
    <div v-if="this.$route.name === 'Miscellaneous'">
      <Miscellaneous />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import Miscellaneous from "@/views/companyApp/miscellaneous/Miscellaneous.vue";

export default {
  name: 'MiscellaneousParent',
  components: {
    Miscellaneous
  }
}
</script>